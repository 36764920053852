import React from 'react';
import Copyright from 'components/Copyright/Copyright';
import Label from 'components/Label/Label';

const Footer = props => {
  return (
    <div id="other">
      <Copyright name="Mikel Jagan" />

      <Label>
        Made with Xerum<sup>™</sup>
      </Label>
    </div>
  );
};

export default Footer;