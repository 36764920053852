import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from 'scenes/App/App';
import './index.scss';

const MyApp = (
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);

const target = document.getElementById('root');

// React 18
// ReactDOM.createRoot(target).render(MyApp);

// React 17
ReactDOM.render(MyApp, target);