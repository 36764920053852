import React, { useContext } from 'react';
import { sections } from './skillsLists';
import { AppContext } from 'scenes/App/App';
import Accordion from 'components/Accordion/Accordion';
import './Skills.scss';

const Skills = props => {
  const { setActive } = useContext(AppContext);

  return (
    <div id="workflow">
      <Accordion
        openIcon="fa-thin fa-minus"
        closeIcon="fa-thin fa-plus"
        sections={sections}
        openDefault={sections[0]}
        callback={newVal => setActive(newVal)}
      />
    </div>
  );
};

export default Skills;