import React, { useContext } from 'react';
import { AppContext } from 'scenes/App/App';
import Button from 'components/Button/Button';
import projects from './projects';
import colors from 'theme/colors.scss';
import './Showcase.scss';

const Showcase = props => {
  const { active } = useContext(AppContext);

  const buildLinks = links => links?.map((link, index) => {
    const { text, url } = link;

    if (text.toLowerCase() === "placeholder") {
      return <div key={index} className="placeholder" />
    }

    return (
      <Button
        key={index}
        style={{ backgroundColor: active?.color || colors.slate }}
        hoverStyle={{ backgroundColor: active?.color || colors.slate }}
        text={text}
        url={url}
      />
    );
  });

  const myProjects = projects.map((project, index) => {
    const { madeWith, image, name, type, description, credentials, links, footnote } = project;
    const previewStyle = {
      backgroundImage: `url(${image})`,
      fontSize: "6rem",
     };

    return (
      <div key={index} id="showcase">
        <div className="details">
          <div>
            <div className="labelContainer">
              {madeWith}
            </div>

            <div className="preview" style={previewStyle}>
              {typeof image === "object" && image}
            </div>

            <div className="appInfo">
              <div>
                <h1>{name}</h1>
                {type && <h4>{type}</h4>}
              </div>

              {credentials && (
                <div className="credentials">
                  {credentials}
                </div>
              )}
            </div>

            {description}

            <p className="footnote">
              {footnote}
            </p>
          </div>

          <div className="links">
            {buildLinks(links)}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div id="projectContainer">
      {myProjects}
    </div>
  )
};

export default Showcase;