import React, { Fragment, useEffect, useState, useCallback, createContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { addEvent, removeEvent } from 'helpers/utilityHelpers';
import { sections } from 'scenes/Skills/skillsLists';
import Showcase from 'scenes/Showcase/Showcase';
import About from 'scenes/About/About';
import Footer from './Footer';
import Skills from 'scenes/Skills/Skills';
import NotFound from 'components/NotFound/NotFound';
import '@fortawesome/fontawesome-pro/css/all.css';
import './App.scss';

export const AppContext = createContext();
const mobileThreshold = 768;

const App = props => {
  const [ active, setActive ] = useState(sections[0]);
  const [ isMobile, setIsMobile ] = useState(window.innerWidth <= mobileThreshold);

  const toggleMobileMenu = useCallback(e => {
    const target = e.currentTarget;
    const width = target.innerWidth;

    if (width <= mobileThreshold && !isMobile) setIsMobile(true);
    if (width >= mobileThreshold && isMobile) setIsMobile(false);
  }, [isMobile]);

  useEffect(() => {
    addEvent('resize', e => toggleMobileMenu(e));
    return () => removeEvent('resize', e => toggleMobileMenu(e));
  }, [isMobile, toggleMobileMenu]);

  const buildApp = () => {
    return (
      <Fragment>
        <section id="content" className="scrollbar">
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/featured" element={<Showcase />} />
            <Route path='/' element={<Navigate to="/featured" />} />
          </Routes>
        </section>

        <section id="panel">
          <About />
          <Skills />
        </section>

        <footer>
          <Footer />
        </footer>
      </Fragment>
    );
  };

  return (
    <AppContext.Provider value={{ active, setActive }}>
      <main>
        {buildApp()}
      </main>
    </AppContext.Provider>
  );
};

export default App;
