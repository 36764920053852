import colors from 'theme/colors.scss';

const frontEnd = [
  {
    name: 'UI Library',
    desc: 'React'
  },
  {
    name: 'Routing',
    desc: 'React Router'
  },
  {
    name: 'State Management',
    desc: 'Redux / Context'
  },
  {
    name: 'Real-time Data',
    desc: 'Web-sockets'
  },
  {
    name: 'Data Visualization',
    desc: 'D3 / ChartJS'
  },
  {
    name: '3-D',
    desc: 'BabylonJS'
  },
  {
    name: 'Testing',
    desc: 'React Testing Library (Jest)'
  },
  {
    name: 'Foundation',
    desc: 'HTML, CSS / SASS / Styled Components, JavaScript'
  },
];

const backEnd = [
  {
    name: 'API Creation',
    desc: 'Node + Express + Postman'
  },
  {
    name: 'API Consuming',
    desc: 'Fetch/Axios'
  },
  {
    name: 'Database',
    desc: 'MongoDB + Mongoose'
  },
  {
    name: 'Authentication',
    desc: 'JSON Web Token'
  },
];

const integration = [
  {
    name: 'Deployment and CI',
    desc: 'Netlify + GitHub Actions'
  },
  {
    name: 'Monitoring',
    desc: 'Sentry'
  },
  {
    name: 'Analytics',
    desc: 'Google Analytics'
  },
];

const misc = [
  {
    name: 'IDE',
    desc: 'Visual Studio Code'
  },
  {
    name: 'OS',
    desc: 'Windows / Linux / macOS'
  },
  {
    name: 'Version Control',
    desc: 'Git, Perforce'
  },
  {
    name: 'Package Managers',
    desc: 'Yarn / NPM'
  },
  {
    name: 'Visual Design',
    desc: 'Figma / Photoshop'
  },
  {
    name: 'Illustration',
    desc: 'Illustrator'
  },
];

export const sections = [
  {
    title: 'Front-end',
    color: colors.darkBlue,
    content: frontEnd.map((skill, index) => (
      <div key={index}>
        <strong>
          {skill.name}
        </strong>:&nbsp;

        {skill.desc}
      </div>
    )),
  },
  {
    title: 'Back-end',
    color: colors.orange,
    content: backEnd.map((skill, index) => (
      <div key={index}>
        <strong>
          {skill.name}
        </strong>:&nbsp;

        {skill.desc}
      </div>
    )),
  },
  {
    title: 'Integration',
    color: colors.purple,
    content: integration.map((skill, index) => (
      <div key={index}>
        <strong>
          {skill.name}
        </strong>:&nbsp;

        {skill.desc}
      </div>
    )),
  },
  {
    title: 'Misc.',
    color: colors.brown,
    content: misc.map((skill, index) => (
      <div key={index}>
        <strong>
          {skill.name}
        </strong>:&nbsp;

        {skill.desc}
      </div>
    )),
  },
];