import React from 'react';
import Social from 'components/Social/Social';
import profilePic from 'static/profilePic.jpg';
import './About.scss';

const socialNetworks = [
  {
    name: "GitHub",
    path: "https://github.com/MikelMNJ",
    icon: "fa-brands fa-github",
  },
  {
    name: "LinkedIn",
    path: "https://linkedin.com/in/mikelmnj",
    icon: "fa-brands fa-linkedin-in",
  },
  {
    name: "Resume",
    path: "https://drive.google.com/file/d/1hXYCE-8pdDK4EBLO2tFEZE70xOBo5JFk/view?usp=sharing",
    icon: "fa-solid fa-file-arrow-down",
  },
];

const About = props => {
  return (
    <div id="about">
      <div className="title">
        <img src={profilePic} alt="Profile." />

        <div>
          <h2>Mikel Jagan</h2>
          <h3>Full-stack Engineer | MERN</h3>
          <Social asTooltip tipPosition="bottom" networks={socialNetworks} />
        </div>
      </div>

      <p className="description">
        I started my career as a 3-D Artist and transitioned to Developer somewhere along the way.
        I specialize in engineering React applications and love learning new things.<br />
        <br />
        I'm a fan of super hero movies and I enjoy the occasional
        single player game.  My favorite YouTube content involves anything with spacetime, 3-D content creation
        and technology &mdash; computer hardware, artificial intelligence etc.
      </p>
    </div>
  );
};

export default About;