import { Fragment } from 'react';
import Accordion from 'components/Accordion/Accordion';
import Label from 'components/Label/Label';

const title = 'Summary';
const inspiration = 'Inspiration';
const techStack = 'Tech stack';

const budgieSections = [
  {
    title,
    content:
      <Fragment>
        <p>
          Automate your finances, track spending, and achieve goals.
        </p>

        <p>
          Safely, and securely, connect to your financial institution with intuitive tools
          to tag transactions, create expenses, auto-fund or spend from those expenses and
          save for goals.  You can also visualize your spending habits for optimal financial
          health.
        </p>
      </Fragment>,
  },
  {
    title: inspiration,
    content:
      <Fragment>
        A neo-bank, called Simple, had the best budgeting tools I had ever used.
        It was acquired and shut down, immediately.  I was a user of Simple,
        and after searching for years, was unable to find a replacement. Now that DNA
        is being used to create Budgie.
      </Fragment>,
  },
  {
    title: techStack,
    content:
      <Fragment>
        <p>
          <strong>UI Library</strong>:<br />
          React
        </p>

        <p>
          <strong>Routing</strong>:<br />
          React Router
        </p>

        <p>
          <strong>State Management</strong>:<br />
          Redux
        </p>

        <p>
          <strong>Data Broker</strong>:<br />
          Plaid
        </p>

        <p>
          <strong>Data Visualization</strong>:<br />
          ChartJS
        </p>

        <p>
          <strong>API Creation</strong>:<br />
          Node + Express + Postman
        </p>

        <p>
          <strong>API Consuming</strong>:<br />
          Fetch
        </p>

        <p>
          <strong>Database</strong>:<br />
          MongoDB + Mongoose
        </p>

        <p>
          <strong>Authentication</strong>:<br />
          JSON Web Token
        </p>

        <p>
          <strong>Deployment and CI</strong>:<br />
          Netlify + GitHub Actions
        </p>

        <p>
          <strong>Monitoring</strong>:<br />
          Sentry
        </p>

        <p>
          <strong>Analytics</strong>:<br />
          Google Analytics
        </p>
      </Fragment>
  },
];

const xolioSections = [
  {
    title,
    content:
      <Fragment>
        <p>
          Everything you need to engage the
          crypto market in one place.
        </p>

        <p>
          Charts with indicators for technical analysis, portfolio management with real-time
          values, a broader view of the overall market cap, as well as the latest news for
          fundamental analysis.  It also has Coinbase integration for trading*.
        </p>

        <p className='footnote'>
          *For security reasons, Coinbase integration has been
          disabled/removed for the demo account.
        </p>
      </Fragment>,
  },
  {
    title: inspiration,
    content:
      <Fragment>
        Opening TradingView, Coinbase, Ledger Live, Coin Marketcap and an RSS feed
        multiple times a day quickly became a pain point.  Some of those interfaces
        felt over-engineered and cluttered. Consolidating into one app felt
        necessary.
      </Fragment>,
  },
  {
    title: techStack,
    content:
      <Fragment>
        <p>
          <strong>UI Library</strong>:<br />
          React
        </p>

        <p>
          <strong>Routing</strong>:<br />
          React Router
        </p>

        <p>
          <strong>State Management</strong>:<br />
          Redux
        </p>

        <p>
          <strong>Real-time Data</strong>:<br />
          Web-sockets
        </p>

        <p>
          <strong>Data Visualization</strong>:<br />
          D3/ChartJS
        </p>

        <p>
          <strong>API Creation</strong>:<br />
          Node + Express + Postman
        </p>

        <p>
          <strong>API Consuming</strong>:<br />
          Fetch
        </p>

        <p>
          <strong>Database</strong>:<br />
          MongoDB + Mongoose
        </p>

        <p>
          <strong>Authentication</strong>:<br />
          JSON Web Token
        </p>

        <p>
          <strong>Deployment and CI</strong>:<br />
          Netlify
        </p>

        <p>
          <strong>Monitoring</strong>:<br />
          Sentry
        </p>
      </Fragment>
  },
];

const xerumSections = [
  {
    title,
    content:
      <Fragment>
        <p>
          Highly customizable components for accelerated app creation.
        </p>

        <p>
          Xerum's strength is that it provides components found in most
          applications &mdash; without overwhelming the user with several iterations of the
          same component.  Instead, key props are used to achieve the look and functionality
          needed from a single component. Styled components ensures there are no conflicts with
          your existing project's CSS.
        </p>
      </Fragment>,
  },
  {
    title: inspiration,
    content:
      <Fragment>
        Existing UI libraries never seem to be without friction. I always feel like my app is
        being made to work around the limitations of third-party components, rather than
        smoothly integrating into my app the way I expect &mdash; so I created Xerum...
      </Fragment>
  },
  {
    title: techStack,
    content:
      <Fragment>
        <p>
          <strong>UI Library</strong>:<br />
          React v16.8+
        </p>

        <p>
          <strong>Package platform</strong>:<br />
          NPM
        </p>

        <p>
          <strong>Peer Dependencies</strong>:<br />
          Font Awesome • Hello Pangea's DND • Formik • GSAP •JWT Decode • Lodash • Moment • React
          • React DOM • React Router DOM • Styled Components • Yup
        </p>
      </Fragment>
  },
];

const frontEndTemplateSections = [
  {
    title,
    content:
      <Fragment>
        <p>
          Build a scalable, production ready, front-end for your web application.
        </p>

        <p>
          <strong>Features include</strong>: routing, state management, notifications,
          authentication routes + forms, error monitoring, testing, analytics, design systems,
          ES lint rules, middleware support and much more...
          {/* API caching, rate limiting, back-end routes, email dispatching and database interaction/model
          schema creation with mongoose. */}
        </p>

        <p className='footnote'>
          *Authentication has been disabled for the demo to
          allow access to the app. Visit&nbsp;
          <a href='https://front-end-template.netlify.app/login' rel='noreferrer' target='_blank'>/login</a>,&nbsp;
          <a href='https://front-end-template.netlify.app/create-account' rel='noreferrer' target='_blank'>/create-account</a>,&nbsp;
          <a href='https://front-end-template.netlify.app/reset-password' rel='noreferrer' target='_blank'>/reset-password</a>&nbsp;
          or&nbsp;
          <a href='https://front-end-template.netlify.app/set-password' rel='noreferrer' target='_blank'>/set-password</a>&nbsp;
          for forms.
        </p>
      </Fragment>,
  },
  {
    title: inspiration,
    content:
      <Fragment>
        More than half the battle in getting started is taking the first step.  Unfortunately,
        initial setup and testing prevents a lot of great app ideas from seeing the light of day.
        Now all that's needed is 'git clone...' and a little time with the&nbsp;
        <a href='https://github.com/MikelMNJ/front-end-template' rel='noreferrer' target='_blank'>documentation</a>.
      </Fragment>,
  },
  {
    title: techStack,
    content:
      <Fragment>
        <p>
          <strong>UI Library</strong>:<br />
          React
        </p>

        <p>
          <strong>Routing</strong>:<br />
          React Router
        </p>

        <p>
          <strong>State Management</strong>:<br />
          Redux
        </p>

        {/* <p>
          <strong>API Creation</strong>:<br />
          Node + Express + Postman
        </p>

        <p>
          <strong>API Consuming</strong>:<br />
          Fetch
        </p>

        <p>
          <strong>Database</strong>:<br />
          MongoDB + Mongoose
        </p> */}

        <p>
          <strong>Authentication</strong>:<br />
          JSON Web Token
        </p>

        <p>
          <strong>Deployment and CI</strong>:<br />
          Netlify
        </p>

        <p>
          <strong>Monitoring and analytics</strong>:<br />
          Sentry + Google Analytics
        </p>
      </Fragment>
  },
];

const backEndTemplateSections = [
  {
    title,
    content:
      <Fragment>
        <p>
          A simple back-end service for small to medium sized applications.
        </p>

        <p>
          <strong>Features include</strong>:
          API caching, rate limiting, back-end routes, email dispatching and database interaction/model
          schema creation with mongoose.
        </p>
      </Fragment>,
  },
  {
    title: inspiration,
    content:
      <Fragment>
        My projects and apps needed a quick solution for a simple back-end that can be deployed easily&nbsp;
        &mdash; something with most features a common app would need. It also gives me more personal
        practice with Node and Express.
      </Fragment>,
  },
  {
    title: techStack,
    content:
      <Fragment>
        <p>
          <strong>API Creation</strong>:<br />
          Node + Express + Postman
        </p>

        <p>
          <strong>API Consuming</strong>:<br />
          Fetch
        </p>

        <p>
          <strong>Database</strong>:<br />
          MongoDB + Mongoose
        </p>

        <p>
          <strong>Middleware</strong>:<br />
          Route authentication, CORS, API Caching, Rate limiting
        </p>

        <p>
          <strong>Email dispatch</strong>:<br />
          SendGrid
        </p>

        <p>
          <strong>Authentication</strong>:<br />
          JSON Web Token
        </p>
      </Fragment>
  },
];

const stateWranglerSections = [
  {
    title,
    content:
      <Fragment>
        <p>
          Immutable state management for Redux.
        </p>

        <p>
          State Wrangler is a Redux compatible library that allows you to manage global state.
          It is simple, consistent and easy to use.
        </p>
      </Fragment>,
  },
  {
    title: inspiration,
    content:
      <Fragment>
        After working with ImmutableJS, it became apparent that ImmutableJS' system for
        retrieving, modifying or removing items from state was not intuitive.
        State Wrangler solves this by providing a more intuitive system for
        working with immutable state.
      </Fragment>
  },
  {
    title: techStack,
    content:
      <Fragment>
        <p>
          <strong>State Library</strong>:<br />
          Redux
        </p>

        <p>
          <strong>Package platform</strong>:<br />
          NPM
        </p>
      </Fragment>
  },
];

const projects = [
  // Budgie
  {
    madeWith:
      <Fragment>
        <Label>
          Made with Xerum<sup>™</sup>&nbsp;
          + State Wrangler
        </Label>
      </Fragment>,
    image: <i className='fa-duotone fa-wallet' />,
    name:
      <Fragment>
        Budgie<sup>™</sup>
      </Fragment>,
    type: 'Web App',
    description:
      <Accordion
        openIcon='fa-thin fa-minus'
        closeIcon='fa-thin fa-plus'
        openDefault={null}
        sections={budgieSections}
      />,
    credentials: null,
    links: [
      {
        text: 'Placeholder',
      },
      {
        text: 'Placeholder',
      },
      {
        text: 'Launch App',
        url: 'https://mybudgie.app/waitlist',
      },
    ],
    footnote: 'Budgie is currently in development.',
  },

  // Xolio
  {
    madeWith: null,
    image: <i className='fa-duotone fa-chart-candlestick' />,
    name:
      <Fragment>
        Xolio<sup>™</sup>
      </Fragment>,
    type: 'Web App',
    description:
      <Accordion
        openIcon='fa-thin fa-minus'
        closeIcon='fa-thin fa-plus'
        openDefault={null}
        sections={xolioSections}
      />,
    credentials:
      <Fragment>
        <strong>User</strong>: demo@xolio.io<br />
        <strong>Pass</strong>: D3mopass!
      </Fragment>,
    links: [
      {
        text: 'Placeholder',
      },
      {
        text: 'Placeholder',
      },
      {
        text: 'Launch App',
        url: 'https://xolio.io',
      },
    ],
    footnote: null,
  },

  // Xerum
  {
    madeWith:
      <Fragment>
        <Label>
          Made with Xerum<sup>™</sup>
        </Label>
      </Fragment>,
    image: <i className='fa-duotone fa-code' />,
    name:
      <Fragment>
        Xerum<sup>™</sup>
      </Fragment>,
    type: 'Component Library',
    description:
      <Accordion
        openIcon='fa-thin fa-minus'
        closeIcon='fa-thin fa-plus'
        openDefault={null}
        sections={xerumSections}
      />,
    credentials: null,
    links: [
      {
        text: 'Launch App',
        url: 'https://xerum.netlify.app',
      },
      {
        text: 'View on GitHub',
        url: 'https://github.com/MikelMNJ/xerum',
      },
      {
        text: 'View on NPM',
        url: 'https://www.npmjs.com/package/xerum',
      },
    ],
    footnote: null,
  },

  // Front-end Template
  {
    madeWith:
      <Fragment>
        <Label>
          Includes Xerum<sup>™</sup>&nbsp;
          + State Wrangler
        </Label>
      </Fragment>,
    image: <i className='fa-duotone fa-browser' />,
    name: 'Front-end Template',
    type: 'Web App Template',
    description:
      <Accordion
        openIcon='fa-thin fa-minus'
        closeIcon='fa-thin fa-plus'
        openDefault={null}
        sections={frontEndTemplateSections}
      />,
    credentials: null,
    links: [
      {
        text: 'Placeholder',
      },
      {
        text: 'Launch App',
        url: 'https://front-end-template.netlify.app',
      },
      {
        text: 'View on GitHub',
        url: 'https://github.com/MikelMNJ/front-end-template',
      },
    ],
    footnote: null,
  },

  // Back-end Template
  {
    madeWith: null,
    image: <i className='fa-duotone fa-server' />,
    name: 'Back-end Template',
    type: 'Web App Template',
    description:
      <Accordion
        openIcon='fa-thin fa-minus'
        closeIcon='fa-thin fa-plus'
        openDefault={null}
        sections={backEndTemplateSections}
      />,
    credentials: null,
    links: [
      {
        text: 'Placeholder',
      },
      {
        text: 'Launch App',
        url: 'https://back-end-template.netlify.app',
      },
      {
        text: 'View on GitHub',
        url: 'https://github.com/MikelMNJ/back-end-template',
      },
    ],
    footnote: null,
  },

  // State Wrangler
  {
    madeWith: null,
    image:
      <>
        <i className='fa-duotone fa-ellipsis' style={{ position: 'relative', bottom: '-1.75rem' }} />
        <i className='fa-duotone fa-brackets-curly' />
      </>,
    name: 'State Wrangler',
    type: 'State Manager',
    description:
      <Accordion
        openIcon='fa-thin fa-minus'
        closeIcon='fa-thin fa-plus'
        openDefault={null}
        sections={stateWranglerSections}
      />,
    credentials: null,
    links: [
      {
        text: 'Placeholder',
      },
      {
        text: 'View on GitHub',
        url: 'https://github.com/MikelMNJ/state-wrangler',
      },
      {
        text: 'View on NPM',
        url: 'https://npmjs.com/package/state-wrangler',
      },
    ],
    footnote: null,
  },
];

export default projects;